<template>
  <ion-page class="page">
    <div class="bg-transparent my-auto">
      <ion-card class="p-4">
        <div v-if="loading" class="d-flex flex-column align-items-center">
          <ion-skeleton-text animated class="mt-3" style="width: 50px; height: 50px; border-radius: 50px" />
          <ion-skeleton-text animated class="my-3" style="width: 250px; height: 18px; border-radius: 10px" />
          <ion-skeleton-text animated class="mb-2" style="width: 100%; height: 10px; border-radius: 10px" />
          <ion-skeleton-text animated class="mb-2" style="width: 100%; height: 10px; border-radius: 10px" />
        </div>
        <div v-else class="text-center">
          <div v-if="is_email_verified"> <!-- If token is present in the URL -->
            <ion-icon
              style="font-size: 40px !important"
              :icon="checkmarkCircle"
            />
            <div class="title my-2">
              {{ is_email_verified ? 'Woohoo!! Email verified!' : 'Verification Failed' }}
            </div>
            <div class="my-3 text-color" v-if="!is_email_verified">
              This verification link has expired or is invalid. Please log in to your account to request a new one.
            </div>
          </div>

          <!-- Manual entry -->
          <div v-else class="mt-3 mb-4 center-text">
            <form @submit.prevent="submitCode" class="d-flex align-items-center justify-content-center">
              <ion-input
                v-if="!isAuthenticated"
                class="e-form mr-2 c-input"
                v-model="identifier"
                placeholder="Enter username or email"
              ></ion-input>
              <ion-input
                class="e-form mr-2 c-input"
                v-model="verificationCode"
                placeholder="Enter verification code"
              ></ion-input>
              <ion-button type="submit" size="large">
                Submit
              </ion-button>
            </form>
          </div>
          
          <!-- Navigation -->
          <div>
            <router-link :to="is_email_verified ? '/myhome' : '/login'">
              <ion-button>
                {{ is_email_verified ? 'TAKE ME HOME' : 'LOG IN' }}
              </ion-button>
            </router-link>
          </div>
        </div>
      </ion-card>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import { emailVerification } from '@/shared/actions/users';
import { closeCircle, checkmarkCircle } from 'ionicons/icons';
import { authStore } from '@/shared/pinia-store/auth';
import logger from '@/shared/services/logger';
import { toast } from '../native';
const { hydrateUser, isAuthenticated } = authStore();

const is_email_verified = ref(false);
const verificationCode = ref('');
const loading = ref(true);
const formLoading = ref(false);
const identifier = ref('');

const submitCode = async () => {
  if (formLoading.value) return;
  if (!isAuthenticated.value && !identifier.value?.trim()) {
    toast.show('Please enter your username or email', 'nonative', 'danger');
    return;
  }
  const code = verificationCode.value?.trim()
  if (!code) return;
  if (!/^\d{6}$/.test(code)) {
    toast.show('Invalid verification code', 'nonative', 'danger');
    return;
  }
  formLoading.value = true;
  let toasted = false;
  try {
    const res = await emailVerification(code, identifier.value);
    is_email_verified.value = res.is_email_verified;
    if (res.is_email_verified) {
      toast.show('Email verified successfully!', 'nonative', 'success');
    } else if (res.message) {
      toast.show(res.message, 'nonative', 'danger');
      toasted = true;
    }
    if (isAuthenticated.value) await hydrateUser();
  } catch (e) {
    if (!toasted) toast.show('Email verification failed', 'nonative', 'danger');
  } finally {
    formLoading.value = false;
  }
};

const confirmEmailVerification = async (token: any) => {
  const res = await emailVerification(token);
  is_email_verified.value = res.is_email_verified;
  // if (res.message) {
  //   toast.show(res.message, 'nonative', 'primary');
  // }
  if (is_email_verified.value) await hydrateUser();
  loading.value = false;
};

const linkToken = computed(() => {
  const router = useRouter();
  return router.currentRoute.value.query.token;
});

onMounted(async () => {
  const { isAuthenticated } = authStore();
  const router = useRouter();
  const token = router.currentRoute.value.query.token;

  if (token) {
    try {
      await confirmEmailVerification(token);
    } catch (e) {
      logger.error({
        e,
        loc: 'email-verification',
        msg: 'Error in email verification',
      } as any);
      loading.value = false;
    }
  } else {
    loading.value = false;
  }

  if (isAuthenticated.value) {
    await hydrateUser();
  }
});
</script>
<style lang="sass" scoped>
.page
  min-height: 80vh

.title
  font-size: 25px
  font-weight: bold
  color: #214163

.dark .text-color
  color: white

.e-form
  --padding-start: 10px
  --padding-end: 10px
  text-align: left
  max-width: 200px
</style>
